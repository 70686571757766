import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import SymptommsArrow from 'components/HerbalSymptoms/SymptomsArrow';
import SymptommsBlock from 'components/HerbalSymptoms/SymptomsBlock';

import { IPropsHerbalSymptoms } from './models';
import './HerbalSymptoms.scss';

const HerbalSymptoms: FC<IPropsHerbalSymptoms> = ({ data }) => {
  const {
    properties: {
      logoReckit,
      logoReckitAlt,
      logoStrep,
      logoStrepAlt,
      symptompsTitles,
      title,
      symptomsBlock,
      symptomsArrow,
    },
  } = data;

  return (
    <div data-test="HerbalSymptoms" className="herbal-symptoms">
      <div className="herbal-symptoms__wrapper">
        <div className="herbal-symptoms__logo-wrapper">
          <GatsbyImage
            image={logoStrep}
            alt={logoStrepAlt}
            className="herbal-symptoms__logo--big"
          />
          <GatsbyImage
            image={logoReckit}
            alt={logoReckitAlt}
            className="herbal-symptoms__logo--small"
          />
        </div>
        <DangerouslySetInnerHtml html={title} className="herbal-symptoms__title" />
        <div className="herbal-symptoms__symptoms">
          <div className="herbal-symptoms__left">
            {symptompsTitles?.map((item) => (
              <DangerouslySetInnerHtml
                key={item?.properties.text}
                html={item?.properties.text}
                className="herbal-symptoms__subtitle"
              />
            ))}
          </div>
          <div className="herbal-symptoms__right">
            <div className="herbal-symptoms__right-wrapper">
              <div className="herbal-symptoms__right-arrow">
                {symptomsArrow.map((item) => (
                  <SymptommsArrow
                    key={item.properties.image[0]?.properties.image.fallbackUrl}
                    data={item}
                  />
                ))}
              </div>
              <div className="herbal-symptoms__right-content">
                {symptomsBlock.map((item) => (
                  <SymptommsBlock key={item.properties.text[0]?.properties.text} data={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentHerbalSymptoms on THerbalSymptomsStructure {
    properties {
      logoStrep {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 440, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      logoStrepAlt
      logoReckit {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 35, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      logoReckitAlt
      title
      symptomsArrow {
        ...FragmentSymptomsBlock
      }
      symptompsTitles {
        properties {
          text
        }
      }
      symptomsBlock {
        ...FragmentSymptomsBlock
      }
    }
  }
`;

export default HerbalSymptoms;
