import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout, Container } from 'layout';
import HerbalBanner from 'components/HerbalBanner/HerbalBanner';
import MomentsBlock from 'components/MomentsBlock/MomentsBlock';
import HerbalProducts from 'components/HerbalProducts/HerbalProducts';
import HerbalBenefits from 'components/HerbalBenefits/HerbalBenefits';
import HerbalSymptoms from 'components/HerbalSymptoms/HerbalSymptoms';

import { IPropsWhereToBuyPage } from './models';

const HerbalPage: FC<IPropsWhereToBuyPage> = ({ data: { pageData } }) => {
  const {
    defaultCompositions,
    seo,
    banner,
    moments,
    products,
    benefits,
    symptoms,
    customCompositions,
    pageLogo,
    pageLogoAlt,
  } = pageData;

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      customCompositions={customCompositions}
      seo={seo}
      className="herbal-page-layout"
      anefpBlockCustomization={{ isHideBlock: true }}
      pageLogo={pageLogo}
      pageLogoAlt={pageLogoAlt}
    >
      <HerbalBanner data={banner[0]} />
      {symptoms?.[0] ? <HerbalSymptoms data={symptoms[0]} /> : null}
      <Container>
        <MomentsBlock data={moments[0]} />
      </Container>
      <HerbalProducts
        data={products[0]}
        prevAriaLabel={defaultCompositions.siteSettings.ariaLabelPrev}
        nextAriaLabel={defaultCompositions.siteSettings.ariaLabelNext}
      />
      <HerbalBenefits
        data={benefits[0]}
        prevAriaLabel={defaultCompositions.siteSettings.ariaLabelPrev}
        nextAriaLabel={defaultCompositions.siteSettings.ariaLabelNext}
      />
    </Layout>
  );
};

export const query = graphql`
  query ($link: String!, $lang: String!) {
    pageData: herbal(link: { eq: $link }, lang: { eq: $lang }) {
      pageLogo {
        gatsbyImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      pageLogoAlt
      seo {
        ...FragmentSeo
      }
      defaultCompositions {
        ...FragmentDefaultCompositions
      }
      customCompositions {
        ...FragmentCustomCompositions
      }
      banner {
        ...FragmentHerbalBanner
      }
      moments {
        ...FragmentMomentsBlock
      }
      products {
        ...FragmentHerbalProducts
      }
      benefits {
        ...FragmentHerbalBenefits
      }
      symptoms {
        ...FragmentHerbalSymptoms
      }
    }
  }
`;

export default HerbalPage;
