import React, { FC } from 'react';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';

import { IPropsSymptomsArrow } from '../SymptomsArrow/models';
import './SymptomsBlock.scss';

const SymptomsBlock: FC<IPropsSymptomsArrow> = ({ data }) => {
  const {
    properties: { image, text, bgColor },
  } = data;

  const bgColorVariant = bgColor?.[0];

  return (
    <div data-test="SymptomsBlock" className="symptoms-block">
      <div className="symptoms-block__text-wrapper">
        {text.map((textItem) => (
          <DangerouslySetInnerHtml
            key={textItem?.properties.text}
            html={textItem?.properties.text}
            className={classNames('symptoms-block__text', {
              [`symptoms-block__text--${bgColorVariant}`]: bgColorVariant,
            })}
          />
        ))}
        <GatsbyImage
          alt={image[0]?.properties.imageAlt}
          image={image[0]?.properties.image}
          className="symptoms-block__image"
        />
      </div>
    </div>
  );
};

export default SymptomsBlock;
