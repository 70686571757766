import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';

import { IPropsSymptomsArrow } from './models';
import './SymptomsArrow.scss';

const SymptommsArrow: FC<IPropsSymptomsArrow> = ({ data }) => {
  const {
    properties: { image, text },
  } = data;

  return (
    <div data-test="SymptommsArrow" className="symptoms-arrow">
      <div className="symptoms-arrow__icons">
        {image.map((imageItem) => (
          <GatsbyImage
            key={imageItem?.properties.imageAlt}
            alt={imageItem?.properties.imageAlt}
            image={imageItem?.properties.image}
            className="symptoms-arrow__image"
          />
        ))}
      </div>
      <div className="symptoms-arrow__text">
        {text[0]?.properties.text ? (
          <DangerouslySetInnerHtml
            html={text[0]?.properties.text}
            className="symptoms-arrow__title"
          />
        ) : null}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentSymptomsBlock on THerbalSymptomsArrowStructure {
    properties {
      text {
        properties {
          text
        }
      }
      image {
        properties {
          image {
            ...FragmentGatsbyProps
          }
          imageAlt
        }
      }
      bgColor
    }
  }
`;

export default SymptommsArrow;
